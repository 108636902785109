<template>
  <div class="policy">
    <div class="container">
      <h2>Политика в отношении обработки персональных данных</h2>
      <div class="policy__content">
        <div class="policy__title">1. Общие положения</div>
        <div class="policy__text">
          <p>
            Настоящая политика обработки персональных данных составлена в
            соответствии с требованиями Федерального закона от 27.07.2006.
            №152-ФЗ «О персональных данных» и определяет порядок обработки
            персональных данных и меры по обеспечению безопасности персональных
            данных РМОО «Лига студентов Республики Татарстан» (далее –
            Оператор).
          </p>
          <p>
            1. Оператор ставит своей важнейшей целью и условием осуществления
            своей деятельности соблюдение прав и свобод человека и гражданина
            при обработке его персональных данных, в том числе защиты прав на
            неприкосновенность частной жизни, личную и семейную тайну.
          </p>
          <p>
            2. Настоящая политика Оператора в отношении обработки персональных
            данных (далее – Политика) применяется ко всей информации, которую
            Оператор может получить о посетителях веб-сайта
            <a href="https://liga-forum.ru/">https://liga-forum.ru/</a>
          </p>
        </div>
        <div class="policy__title">
          2. Основные понятия, используемые в Политике
        </div>
        <div class="policy__text">
          <p>
            1.Автоматизированная обработка персональных данных – обработка
            персональных данных с помощью средств вычислительной техники.
          </p>
          <p>
            2.Блокирование персональных данных – временное прекращение обработки
            персональных данных (за исключением случаев, если обработка
            необходима для уточнения персональных данных).
          </p>
          <p>
            3. Веб-сайт – совокупность графических и информационных материалов,
            а также программ для ЭВМ и баз данных, обеспечивающих их доступность
            в сети интернет по сетевому адресу сайта
            <a href=" https://liga-forum.ru/"> https://liga-forum.ru/</a>
          </p>
          <p>
            4. Информационная система персональных данных – совокупность
            содержащихся в базах данных персональных данных, и обеспечивающих их
            обработку информационных технологий и технических средств.
          </p>
          <p>
            5. Обезличивание персональных данных – действия, в результате
            которых невозможно определить без использования дополнительной
            информации принадлежность персональных данных конкретному
            Пользователю или иному субъекту персональных данных.
          </p>
          <p>
            6. Обработка персональных данных – любое действие (операция) или
            совокупность действий (операций), совершаемых с использованием
            средств автоматизации или без использования таких средств, с
            персональными данными, включая сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение персональных
            данных.
          </p>
          <p>
            7. Оператор – государственный орган, муниципальный орган,
            юридическое или физическое лицо, самостоятельно или совместно с
            другими лицами организующие и (или) осуществляющие обработку
            персональных данных, а также определяющие цели обработки
            персональных данных, состав персональных данных, подлежащих
            обработке, действия (операции), совершаемые с персональными данными.
          </p>
          <p>
            8. Персональные данные – любая информация, относящаяся прямо или
            косвенно к определенному или определяемому Пользователю веб-сайта
            <a href=" https://liga-forum.ru/"> https://liga-forum.ru/</a>
          </p>
          <p>
            9. Пользователь – любой посетитель веб-сайта
            <a href=" https://liga-forum.ru/"> https://liga-forum.ru/</a>
          </p>
          <p>
            10. Предоставление персональных данных – действия, направленные на
            раскрытие персональных данных определенному лицу или определенному
            кругу лиц.
          </p>
          <p>
            11. Распространение персональных данных – любые действия,
            направленные на раскрытие персональных данных неопределенному кругу
            лиц (передача персональных данных) или на ознакомление с
            персональными данными неограниченного круга лиц, в том числе
            обнародование персональных данных в средствах массовой информации,
            размещение в информационно-телекоммуникационных сетях или
            предоставление доступа к персональным данным каким-либо иным
            способом.
          </p>
          <p>
            12. Трансграничная передача персональных данных – передача
            персональных данных на территорию иностранного государства органу
            власти иностранного государства, иностранному физическому или
            иностранному юридическому лицу.
          </p>
          <p>
            13.Уничтожение персональных данных – любые действия, в результате
            которых персональные данные уничтожаются безвозвратно с
            невозможностью дальнейшего восстановления содержания персональных
            данных в информационной системе персональных данных и (или) в
            результате которых уничтожаются материальные носители персональных
            данных.
          </p>
        </div>
        <div class="policy__title">
          3. Персональные данные, разрешённые к обработке в рамках настоящей
          Политики, предоставляются Пользователем путём заполнения
          регистрационной формы на сайте
          <a href=" https://liga-forum.ru/"> https://liga-forum.ru/</a> и
          включают в себя следующую информацию:
        </div>
        <div class="policy__text">
          <ol>
            <li>Фамилия</li>
            <li>Имя</li>
            <li>Отчество</li>
            <li>День рождения</li>
            <li>Пол</li>
            <li>Номер телефона</li>
            <li>Регион</li>
            <li>Город</li>
            <li>Образовательное учреждение</li>
            <li>Институт/факультет</li>
            <li>Номер группы</li>
            <li>Telegram</li>
            <li>VKontakte</li>
            <li>Курс обучения</li>
            <li>Размер одежды</li>
            <li>Особенности здоровья</li>
            <li>Предпочтение в питании</li>
          </ol>
          <p>
            Также на сайте происходит сбор и обработка обезличенных данных о
            посетителях (в том числе файлов «cookie») с помощью сервисов
            интернет-статистики (Яндекс Метрика, Гугл Аналитика и других).
          </p>
        </div>
        <div class="policy__title">4. Цель обработки персональных данных</div>
        <div class="policy__text">
          <p>
            Цель обработки персональных данных Пользователя – связь с
            Пользователем;
            <br />Oбработка запросов и заявок от Пользователя;
            <br />Информирование Пользователя посредством отправки электронных
            писем; <br />Предоставление доступа Пользователю к сервисам,
            информации и/или материалам, содержащимся на веб-сайте
            <a href="https://liga-forum.ru/">https://liga-forum.ru/</a>
          </p>
          <p>
            Также Оператор имеет право направлять Пользователю уведомления о
            специальных предложениях и различных событиях. Пользователь всегда
            может отказаться от получения информационных сообщений, направив
            Оператору письмо на
            <a href="mailto:liga.forumrt@yandex.ru">liga.forumrt@yandex.ru</a>
            с пометкой «Отказ от уведомлений о новых продуктах и услугах и
            специальных предложениях».
          </p>
          <p>
            Обезличенные данные Пользователей, собираемые с помощью сервисов
            интернет-статистики, служат для сбора информации о действиях
            Пользователей на сайте, улучшения качества сайта и его содержания.
          </p>
        </div>
        <div class="policy__title">
          5. Правовые основания обработки персональных данных
        </div>
        <div class="policy__text">
          <p>
            Оператор обрабатывает персональные данные Пользователя только в
            случае их заполнения и/или отправки Пользователем самостоятельно
            через специальные формы, расположенные на
            <a href="https://liga-forum.ru/">https://liga-forum.ru/</a> Заполняя
            соответствующие формы и/или отправляя свои персональные данные
            Оператору, Пользователь выражает свое согласие с данной Политикой.
          </p>
          <p>
            Оператор обрабатывает обезличенные данные о Пользователе в случае,
            если это разрешено в настройках браузера Пользователя (включено
            сохранение файлов «cookie» и использование технологии JavaScript).
          </p>
        </div>
        <div class="policy__title">
          6. Порядок сбора, хранения, передачи и других видов обработки
          персональных данных
        </div>
        <div class="policy__text">
          <p>
            Безопасность персональных данных, которые обрабатываются Оператором,
            обеспечивается путем реализации правовых, организационных и
            технических мер, необходимых для выполнения в полном объеме
            требований действующего законодательства в области защиты
            персональных данных.
          </p>
          <p>
            Оператор обеспечивает сохранность персональных данных и принимает
            все возможные меры, исключающие доступ к персональным данным
            неуполномоченных лиц.
          </p>
          <p>
            Персональные данные Пользователя никогда, ни при каких условиях не
            будут переданы третьим лицам, за исключением случаев, связанных с
            исполнением действующего законодательства.
          </p>
          <p>
            В случае выявления неточностей в персональных данных, Пользователь
            может актуализировать их самостоятельно, путем направления Оператору
            уведомления на адрес электронной почты Оператора
            liga.forumrt@yandex.ru с пометкой «Актуализация персональных
            данных».
          </p>
          <p>
            Срок обработки персональных данных является неограниченным.
            Пользователь может в любой момент отозвать свое согласие на
            обработку персональных данных, направив Оператору уведомление
            посредством электронной почты на электронный адрес Оператора
            <a href="mailto:liga.forumrt@yandex.ru">liga.forumrt@yandex.ru</a>
            с пометкой «Отзыв согласия на обработку персональных данных».
          </p>
        </div>
        <div class="policy__title">
          7. Трансграничная передача персональных данных
        </div>
        <div class="policy__text">
          <p>
            Оператор до начала осуществления трансграничной передачи
            персональных данных обязан убедиться в том, что иностранным
            государством, на территорию которого предполагается осуществлять
            передачу персональных данных, обеспечивается надежная защита прав
            субъектов персональных данных.
          </p>
          <p>
            Трансграничная передача персональных данных на территории
            иностранных государств, не отвечающих вышеуказанным требованиям,
            может осуществляться только в случае наличия согласия в письменной
            форме субъекта персональных данных на трансграничную передачу его
            персональных данных и/или исполнения договора, стороной которого
            является субъект персональных данных.
          </p>
        </div>
        <div class="policy__title">8. Заключительные положения</div>
        <div class="policy__text">
          <p>
            Пользователь может получить любые разъяснения по интересующим
            вопросам, касающимся обработки его персональных данных, обратившись
            к Оператору с помощью электронной
            <a href="mailto:liga.forumrt@yandex.ru">
              liga.forumrt@yandex.ru.
            </a>
          </p>
          <p>
            В данном документе будут отражены любые изменения Политики
            Оператором. Политика действует бессрочно до замены ее новой версией.
          </p>
          <div class="policy__title" style="margin-top: 50px">
            Актуальная версия Политики в свободном доступе расположена в сети
            Интернет по адресу
            <a href="https://liga-forum.ru/policy">
              https://liga-forum.ru/policy
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Policy",
};
</script>

<style lang="scss">
.policy {
  margin-top: 60px;
  h2 {
    margin-bottom: 30px;
    font-size: 50px;
    line-height: 75px;
  }
  &__title {
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 15px;
    a {
      color: var(--black2);
      &:hover {
        -webkit-text-stroke-width: 0.5px;
      }
    }
  }
  &__text {
    line-height: 21px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    a {
      color: var(--black2);
      &:hover {
        -webkit-text-stroke-width: 0.5px;
      }
    }
  }
  @include adaptive(tablet-small) {
    h2 {
      font-size: 30px;
      line-height: 50px;
    }
  }
  @include adaptive(phone) {
    h2 {
      font-size: 18px;
      line-height: 22px;
    }
    &__title {
      line-height: 14px;
      margin-bottom: 17px;
    }
    &__text {
      line-height: 16px;
      font-size: 14px;
    }
  }
}
</style>
